.introduction {
  background-color: $video-background;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .embeded-video {
    width: 80%;
    aspect-ratio: 16 / 9;
  }
  .embeded-video iframe {
    width: 100%;
    height: 100%;
  }
}
