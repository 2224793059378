*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #1b2d45;
  margin: 0;
  font-family: Noto Sans JP, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #00214d;
  text-align: center;
  margin-top: 0;
}

h2 {
  font-family: "M PLUS 1p";
  font-weight: 900;
}

a {
  color: #49a8ff;
  text-decoration: none;
}

a:hover {
  color: #ad001d;
}

pre {
  width: 100%;
  overflow-x: scroll;
}

p {
  max-width: 800px;
  margin: 0 auto 1em;
  line-height: 1.6em;
}

.section {
  flex-direction: column;
  justify-content: center;
  padding: 3em 1.5em;
  display: flex;
}

.section h2 {
  padding: 2rem 0;
}

.section:nth-child(2n) {
  background-color: #f2f2f2;
}

.section:nth-child(2n-1) {
  color: #fff;
  background-color: #5daeff;
  padding: 4em 1.5em;
}

.section:nth-child(2n-1) h2 {
  color: #fff;
}

.section .container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.conversion-area {
  flex-direction: row;
  font-family: "M PLUS 1p";
  font-weight: 900;
  display: flex;
}

@media (max-width: 700px) {
  .conversion-area {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.conversion-area .button {
  width: 200px;
  height: 48px;
  background-image: url("conversion-button-bg.e547b0ec.png");
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  margin-left: .5rem;
  display: flex;
}

@media (max-width: 700px) {
  .conversion-area .button {
    width: 80%;
    margin-top: 1rem;
  }
}

.highlight-orange {
  color: #f66638;
}

.batch {
  width: 160px;
  height: 38px;
  background-image: url("batch.28f3b681.png");
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  display: flex;
}

@media (max-width: 700px) {
  .batch {
    width: 80%;
    margin-top: 1rem;
  }
}

.header {
  width: 100%;
  background-color: #ffffff80;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  display: flex;
  position: fixed;
}

.header .title {
  font-family: Potta One, "M PLUS 1p";
}

.cover {
  width: 100%;
  min-height: 75vh;
  text-align: center;
  background-image: url("cover-bg.27c375b3.png");
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cover .container {
  flex-direction: row;
  align-items: center;
  display: flex;
}

@media (max-width: 1024px) {
  .cover .container {
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.cover .left {
  width: 50%;
  flex-direction: column;
  justify-content: center;
  font-family: "M PLUS 1p";
  display: flex;
}

.cover .left .title {
  text-align: left;
  font-size: 3rem;
  font-weight: 800;
}

.cover .left .description {
  text-align: left;
  font-size: 1rem;
  font-weight: 900;
}

@media (max-width: 1024px) {
  .cover .left {
    width: 100%;
  }

  .cover .left .title {
    font-size: 2.5rem;
  }
}

.cover .right img {
  height: 600px;
  position: relative;
}

@media (max-width: 1024px) {
  .cover .right {
    display: none;
  }
}

.mission {
  text-align: center;
  padding: 96px 0;
}

.mission h2 {
  font-family: "M PLUS 1p";
  font-size: 2rem;
  font-weight: 900;
}

@media (max-width: 700px) {
  .mission h2 {
    font-size: 1.5rem;
  }
}

.introduction {
  background-color: #f2f2f2;
}

.introduction .container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.introduction .embeded-video {
  width: 80%;
  aspect-ratio: 16 / 9;
}

.introduction .embeded-video iframe {
  width: 100%;
  height: 100%;
}

.service .features-images {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.service .features-images img {
  max-width: 30%;
  margin: 2rem auto 0;
  padding: 1rem;
}

@media (max-width: 1024px) {
  .service .features-images {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .service .features-images img {
    max-width: 300px;
    margin: 1rem auto 0;
    padding: 1rem;
  }
}

.case {
  font-family: "M PLUS 1p";
  font-weight: 800;
  display: flex;
}

.case .container {
  color: #333;
}

.case .pera {
  display: flex;
}

@media (max-width: 1024px) {
  .case .pera {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.case .left {
  width: 450px;
  background-color: #fff;
  border: 3px solid #333;
  border-radius: 24px;
  padding: 2rem;
}

.case .left .like {
  font-weight: 900;
}

@media (max-width: 1024px) {
  .case .left {
    width: 95vw;
  }
}

.case .left .voice {
  padding: .5rem;
}

.case .left .voice .voice-title {
  font-size: 1.5rem;
}

.case .left .voice .voice-border {
  border-bottom: 1px solid #333;
  margin-top: 8px;
  margin-bottom: 8px;
}

.case .left .voice .voice-body {
  padding-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.65rem;
}

.case .left .avatar img {
  width: 180px;
  height: 180px;
}

.case .left .profile {
  justify-content: space-between;
  display: flex;
}

.case .left .profile .social {
  height: 86px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.case .left .profile .social .name {
  font-size: 2rem;
}

.case .left .profile .social .name:before {
  content: "ぺら";
  font-size: 1rem;
  display: block;
  position: relative;
  top: 1rem;
}

.case .left .profile .social .twitter img {
  width: 42px;
  height: 42px;
  border-radius: 12px;
}

.case .right {
  min-width: 600px;
}

.case .right .qr {
  width: 120px;
  height: 120px;
  margin-left: 1rem;
}

.case .right .only-desktop {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1024px) {
  .case .right {
    width: 95vw;
    min-width: 100%;
    margin-top: 2rem;
  }

  .case .right .only-desktop {
    display: none;
  }
}

.case .right h3 {
  font-size: 1.45rem;
  font-weight: 900;
}

.case .right .border-white {
  border-bottom: 2px solid #333;
  margin: 2rem;
}

@media (max-width: 1024px) {
  .case .right .border-white {
    display: none;
  }
}

.case .right .products {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media (max-width: 1024px) {
  .case .right .products {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}

.product {
  background-color: #fff;
  border: 3px solid #333;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  padding: 1rem .5rem;
  display: flex;
}

@media (max-width: 700px) {
  .product {
    width: 95vw;
    margin-top: 1rem;
    margin-right: 0;
  }
}

.product .details {
  justify-content: center;
  align-items: center;
  display: flex;
}

.product .details img {
  width: 96px;
  height: 96px;
}

.product .details .name {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.product .details .amount, .product .details .price {
  text-align: end;
  line-height: 2.5rem;
}

.product .plane {
  margin: 1rem;
}

.product .point {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.product .point .point-title {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.product .point .point-body {
  height: 150px;
  max-width: 200px;
  background-color: #d9d9d960;
  border-radius: 12px;
  padding-top: 1rem;
  padding-left: .5rem;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .product .point .point-body {
    max-width: 100%;
  }
}

.product .go-demo .demo-button {
  width: 200px;
  height: 40px;
  cursor: pointer;
  background-image: url("batch.28f3b681.png");
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

@media (max-width: 700px) {
  .product .go-demo .demo-button {
    display: flex;
  }
}

.footer {
  color: #abb1ba;
  padding: 2em 1.5em;
  font-size: .8em;
  line-height: 1.4em;
}

.footer .container {
  flex-direction: column-reverse;
  display: flex;
}

.footer .container .footer-navigation {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

@media (min-width: 700px) {
  .footer .container {
    flex-direction: column;
    justify-content: space-between;
  }
}

.footer .container a {
  color: #d98c99;
}

.footer .container a:hover {
  text-decoration: underline;
}

.footer .container .copyright {
  margin-top: 1em;
}

@media (min-width: 700px) {
  .footer .container .copyright {
    margin-top: 0;
  }
}

.parcel p {
  font-size: 18px;
}

/*# sourceMappingURL=index.d357d7f1.css.map */
