.section {
  display: flex;
  flex-direction: column;
  padding: 3em 1.5em;
  justify-content: center;
  h2 {
    padding: 2rem 0;
  }

  &:nth-child(2n) {
    background-color: $background;
  }

  &:nth-child(2n-1) {
    background-color: $card-background;
    padding: 4em 1.5em;
    color: #ffffff;
    h2 {
      color: #ffffff;
    }
  }

  .container {
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
  }
}

.conversion-area {
  display: flex;
  flex-direction: row;
  @media (max-width: $bp-md) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  font-family: "M PLUS 1p";
  font-weight: 900;
  .button {
    width: 200px;
    height: 48px;
    background-image: url(/src/assets/lp/conversion-button-bg.png);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    margin-left: 0.5rem;
    margin-left: 0.5rem;
    @media (max-width: $bp-md) {
      width: 80%;
      margin-top: 1rem;
    }
  }
}

.highlight-orange {
  color: $text-highlight;
}

.batch {
  width: 160px;
  height: 38px;
  font-size: 1.25rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-image: url(/src/assets/lp/batch.png);
  @media (max-width: $bp-md) {
    width: 80%;
    margin-top: 1rem;
  }
}
