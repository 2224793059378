.cover {
  width: 100%;
  min-height: 75vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(/src/assets/lp/cover-bg.png);
  background-size: cover;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;

    @media (max-width: $bp-lg) {
      // background-image: url(/src/assets/lp/cover-hand.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .left {
    width: 50%;
    font-family: "M PLUS 1p";
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      text-align: left;
      font-weight: 800;
      font-size: 3rem;
    }
    .description {
      text-align: left;
      font-weight: 900;
      font-size: 1rem;
    }

    @media (max-width: $bp-lg) {
      width: 100%;
      .title {
        font-size: 2.5rem;
      }
    }
  }

  .right {
    img {
      height: 600px;
      position: relative;
    }

    @media (max-width: $bp-lg) {
      display: none;
    }
  }
}
