.mission {
  padding: 96px 0;
  text-align: center;

  h2 {
    font-size: 2rem;
    font-family: "M PLUS 1p";
    font-weight: 900;
    @media (max-width: $bp-md) {
      font-size: 1.5rem;
    }
  }
}
