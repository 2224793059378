*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: $text;
  // font-family: "M PLUS 1p", sans-serif;
  // font-family: "Potta One", "Noto Sans JP";

  font-family: "Noto Sans JP", sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading;
  margin-top: 0;
  text-align: center;
}

h2 {
  font-family: "M PLUS 1p";
  font-weight: 900;
}

a {
  color: $link;
  text-decoration: none;

  &:hover {
    color: $link-darkened;
  }
}

pre {
  overflow-x: scroll;
  width: 100%;
}

p {
  line-height: 1.6em;
  margin: 0 auto 1em auto;
  max-width: 800px;
}
