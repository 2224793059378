$background: #f2f2f2;
$card-background: #5daeff;
$video-background: #f2f2f2;
$heading: #00214d;
$link: #49a8ff;
$link-darkened: #ad001d;
$link-muted: #d98c99;
$text: #1b2d45;
$text-muted: #abb1ba;
$text-highlight: #f66638;

$bp-sm: 600px;
$bp-md: 700px;
$bp-lg: 1024px;
