@import "./styles/_variables";
@import "./styles/_base";

@import "./styles/_main";

// ヘッダー
@import "./styles/_header";

// カバー
@import "./styles/_cover";

// ミッション
@import "./styles/_mission";

// 紹介動画
@import "./styles/_introduction";

// サービス紹介
@import "./styles/_service";

// 利用者の声
@import "./styles/_case";
@import "./styles/_case-product.scss";

// キャンペーン
@import "./styles/_campaing";

// フッター
@import "./styles/_footer";

@import "./styles/_parcel";
