.service {
  .features-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 30%;
      padding: 1rem;
      margin: 0 auto;
      margin-top: 2rem;
    }

    @media (max-width: $bp-lg) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        max-width: 300px;
        padding: 1rem;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }
  
}
