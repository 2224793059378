.product {
  border: solid 3px #333;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $bp-md) {
    width: 95vw;
    margin-top: 1rem;
    margin-right: 0;
  }
  border-radius: 12px;
  background-color: #fff;
  margin-right: 1rem;
  .details {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 96px;
      height: 96px;
    }
    .name {
      font-size: 1.5rem;
      text-align: center;
      line-height: 2.5rem;
    }
    .amount {
      text-align: end;
      line-height: 2.5rem;
    }
    .price {
      text-align: end;
      line-height: 2.5rem;
    }
  }
  .plane {
    margin: 1rem;
  }

  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    .point-title {
      font-size: 1.2rem;
      margin: 1rem 0;
    }

    .point-body {
      height: 150px;
      background-color: #d9d9d960;
      border-radius: 12px;
      padding-top: 1rem;
      padding-left: 0.5rem;
      font-size: 1rem;
      max-width: 200px;
      @media (max-width: $bp-md) {
        max-width: 100%;
      }
    }
  }

  .go-demo {
    .demo-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      background-image: url(/src/assets/lp/batch.png);
      width: 200px;
      height: 40px;
      border-radius: 12px;
      cursor: pointer;
      @media (max-width: $bp-md) {
        display: flex;
      }
    }
  }
}
