.header {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff80;
  .title {
    font-family: "Potta One", "M PLUS 1p";
  }
  position: fixed;
}
