.case {
  display: flex;
  font-family: "M PLUS 1p";
  font-weight: 800;
  .container {
    color: #333;
  }
  .pera {
    display: flex;
    @media (max-width: $bp-lg) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .left {
    .like {
      font-weight: 900;
    }
    border: solid 3px #333;
    padding: 2rem;
    width: 450px;
    border-radius: 24px;
    background-color: #fff;
    @media (max-width: $bp-lg) {
      width: 95vw;
    }
    .voice {
      padding: 0.5rem;
      .voice-title {
        font-size: 1.5rem;
      }
      .voice-border {
        border-bottom: solid 1px #333;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .voice-body {
        font-size: 1rem;
        line-height: 1.65rem;
        padding-top: 1.5rem;
      }
    }
    .avatar {
      img {
        width: 180px;
        height: 180px;
      }
    }
    .profile {
      display: flex;
      justify-content: space-between;

      .social {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 86px;
        .name {
          font-size: 2rem;
          &:before {
            content: "ぺら";
            display: block;
            font-size: 1rem;
            position: relative;
            top: 1rem;
          }
        }
        .twitter {
          img {
            width: 42px;
            height: 42px;
            border-radius: 12px;
          }
        }
      }
    }
  }

  .right {
    min-width: 600px;
    .qr {
      width: 120px;
      height: 120px;
      margin-left: 1rem;
    }
    .only-desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $bp-lg) {
      width: 95vw;
      margin-top: 2rem;
      min-width: 100%;
      .only-desktop {
        display: none;
      }
    }

    h3 {
      font-weight: 900;
      font-size: 1.45rem;
    }
    .border-white {
      margin: 2rem;
      border-bottom: solid 2px #333;
      @media (max-width: $bp-lg) {
        display: none;
        // margin: 0;
        // margin-top: 2rem;
        // margin-bottom: 2rem;
      }
    }
    .products {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // margin-left: 2rem;
      @media (max-width: $bp-lg) {
        margin-left: 0;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
